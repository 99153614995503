export enum RouteEnum {
  'Index' = 'index',
  'LoginMusicTube' = 'loginMusicTube',
  'LoginCommon' = 'loginCommon',
  'LoginReading' = 'loginReading',
  'DeleteAccount' = 'deleteAccount',
  'Calendar' = 'calendar',
  'Listening' = 'listening',
  'ListeningTest' = 'listeningTest',
  'Reading' = 'reading',
  'Article' = 'article',
  'Speaking' = 'speaking',
  'Writing' = 'writing',
  'AiTutor' = 'aiTutor',
  'Homework' = 'homework',
  'Schedule' = 'schedule',
  'VideoLibrary' = 'videoLibrary',
  'Lesson' = 'lesson',
  'Collection' = 'collection',
  'Toeic' = 'toeic',
  'ToeicCertificate' = 'toeicCertificate',
  'ToeicExam' = 'toeicExam',
  'ToeicRecord' = 'toeicRecord',
  'Review' = 'review',
  'ReadingRecord' = 'readingRecord',
  'CourseRecord' = 'courseRecord',
  'WeeklyTest' = 'weeklyTest',
  'CefrTest' = 'cefrTest',
  'WeeklyTestRecord' = 'weeklyTestRecord',
  'LearningStatistics' = 'learningStatistics',
  'Consulting' = 'consulting',
  'DiaryView' = 'diaryView',
  'DiaryOpenLink' = 'diaryOpenLink',
  'DiaryEdit' = 'diaryEdit',
  'UsageRecord' = 'usageRecord',
  'Login' = 'login',
  'LoginJunior' = 'loginJunior',
  'CourseVideo' = 'courseVideo',
  'CustomHomePage' = 'customHomePage',
  'AdvancedSearch' = 'advancedSearch',
  'Today' = 'today',
  'ThankYou' = 'thankYou',
  'Sitemap' = 'sitemap',
  'ArticleCopy' = 'articleCopy',
  'OrderPlan' = 'orderPlan',
  'PaymentResult' = 'paymentResult',
  'Subscription' = 'subscription',
  'FAQ' = 'FAQ',
  'SpecialOrderPlan' = 'specialOrderPlan',
  'FunCoin' = 'funCoin',
  'MLM' = 'mlm',
  'Donation' = 'donation',
  'Lottery' = 'lottery',
  'LotteryApply' = 'lotteryApply',
  'BigOrder' = 'bigOrder',
  'OrderRecord' = 'orderRecord'
}
