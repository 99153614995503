import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { useAppStore } from '@/stores/app';

export default async function device({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
    const appStore = useAppStore();
    appStore.handleDetectDevice();

    return next();
}
