import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { UtilCommon } from '@/utils/utilCommon';
import { useUserStore } from '@/stores/user';
import { CookiesKeys } from '@/constants/enums/CookiesEnum';

export default async function deleteAccountFilter({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
  // 若點選刪除帳號頁面，但是未登入，將於登入後，再跳轉回刪除帳號頁面
  const deletePage = UtilCommon.getLocalStorage(CookiesKeys.DeleteAccount);
  if (!!deletePage) {
    UtilCommon.setLocalStorage(CookiesKeys.DeleteAccount, false);
    window.location.href = `/delete-account`;
    return;
  }

  return next();
}
