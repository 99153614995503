import useCheckAdIdParam from '@/composables/useCheckAdIdParam';
import { CookiesKeys } from '@/constants/enums/CookiesEnum';
import { LocalStorageKeys } from '@/constants/enums/LocalStorageEnum';
import { UtilCommon } from '@/utils/utilCommon';
import type { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';

/**
 * 子站是ADid原本的是ad_id 先並行處理，待重構子站
*/
async function checkAdId() {
  const searchParams = new URLSearchParams(window.location.search);

  const sessionAdId = sessionStorage.getItem('adId');
  const urlAdId = searchParams.get(CookiesKeys.AdId);
  const adid = urlAdId || sessionAdId; // URL ADID 優先於 Seesion 的
  // console.log('===', adid, urlAdId, sessionAdId)
  if (adid) {
    try {
      UtilCommon.setCookie(CookiesKeys.AdId, adid, 1); //以防各種操作導致沒有adid（仿舊站規則）
      await fetch(`https://funday.asia/api/AdClick.asp?ADid=${adid}`); //等有時間再重寫api吧
    } catch (error) {
      console.log(error);
    }
  }

  //分享訂閱
  const sharingKey = searchParams.get(LocalStorageKeys.SharingKey);
  if (sharingKey) {
    UtilCommon.setLocalStorage(LocalStorageKeys.SharingKey, sharingKey);
  }
  
  // MGM
  const MGMCode = searchParams.get('mgm');
  if (MGMCode) {
    UtilCommon.setLocalStorage(LocalStorageKeys.MGMCode, MGMCode);
  }
}

export default async function adMgmShareKey({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
    await useCheckAdIdParam(); // leon 版本
    await checkAdId();

    return next();
}
