export enum LocalStorageKeys {
  UserStore = 'new-funday-userStore',
  Gender = 'sex',
  SharingKey = 'SharingKey',
  MGMCode = 'MGMCode',
  HasSeenEventCard = 'hasSeenEventCard',
  FunTVExclude = 'funTVExclude',
  FunTVExcludeNew = 'funTVExcludeNew',
  TabsCount = 'tabsCount',
}
