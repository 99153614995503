import type { PackageInfo } from './profile';

export interface MemberInfoI {
  memberId: number;
  userId: number | null;
  customerId: number;
  realName: string;
  nickName: string;
  sex: number;
  birthday: string | null;
  email: string | null;
  photo: string | null;
  startDate: string;
  endDate: string;
  hasLevel: boolean;
  point: number;
  pointDate: string | null;
  level: number;
  levelStep: number;
  isPay: number;
  isFreeUser: number;
  isDemo: boolean;
  isThirdParty: boolean;
  roleType: RoleType;
  canReserveEarly: boolean;
  chatIds: {
    emailCreation: string;
    grammarCheck: string;
    grammarExplanation: string;
    persona: string[];
    translation: string;
    aiAssistant: string;
  };
  packageInfo: PackageInfo | null;
  contractStartDate: string | null;
  reserveDemoDate: number | null
}

export enum RoleType {
  notJunior = 0,
  isJunior = 1
}
