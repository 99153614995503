import compareDates from '@/composables/useCompareDate';
import { useCurrentDay } from '@/composables/useCurrentDay';
import { RouteEnum } from '@/constants/enums/RouteEnum';
import adMgmShareKey from '@/middleware/adMgmShareKey';
import auth from '@/middleware/auth';
import customHome from '@/middleware/customHome';
import deleteAccountFilter from '@/middleware/deleteAccountFilter';
import device from '@/middleware/device';
import freeClass from '@/middleware/freeClass';
import webview from '@/middleware/webview';
import { RoleType } from '@/models/interface/user';
import { useAppStore } from '@/stores/app';
import { usePopStore } from '@/stores/pop';
import { useUserStore } from '@/stores/user';
import { setupLayouts } from 'virtual:generated-layouts';
import { computed } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: RouteEnum.Index,
    component: () => import('@/views/IndexView.vue'),
    meta: {
      middleware: [auth, freeClass, customHome, deleteAccountFilter]
    }
  },
  /** 登入註冊頁 */
  {
    path: '/login',
    name: RouteEnum.Login,
    component: () => import('@/views/LoginView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth, adMgmShareKey, device]
    }
  },
  /** 英語中學 登入註冊頁 */
  {
    path: '/loginJunior',
    name: RouteEnum.LoginJunior,
    component: () => import('@/views/LoginJuniorView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth, adMgmShareKey, device]
    }
  },
  /** 行銷廣告用登入頁「音樂＆電影」 */
  {
    path: '/loginMusicTube',
    name: RouteEnum.LoginMusicTube,
    component: () => import('@/views/LoginMusicTubeView.vue'),
    meta: {
      middleware: [auth, adMgmShareKey, device],
      layout: 'NoLayout'
    }
  },
  /** 行銷廣告用登入頁「通用型」 */
  {
    path: '/loginCommon',
    name: RouteEnum.LoginCommon,
    component: () => import('@/views/LoginCommonView.vue'),
    meta: {
      middleware: [auth, adMgmShareKey, device],
      layout: 'NoLayout'
    }
  },
  /** 行銷廣告用登入頁「文章導讀」 */
  {
    path: '/loginReading',
    name: RouteEnum.LoginReading,
    component: () => import('@/views/LoginReadingView.vue'),
    meta: {
      middleware: [auth, adMgmShareKey, device],
      layout: 'NoLayout'
    }
  },
  /** Android 用的刪除帳號網址 */
  {
    path: '/delete-account',
    name: RouteEnum.DeleteAccount,
    component: () => import('@/views/DeleteAccountView.vue'),
    meta: {
      middleware: [deleteAccountFilter],
      layout: 'NoLayout'
    }
  },
  /** 行事曆 */
  {
    path: '/calendar',
    name: RouteEnum.Calendar,
    component: () => import('@/views/CalendarView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 聽力 */
    path: '/listening',
    name: RouteEnum.Listening,
    component: () => import('@/views/ListeningView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 克漏字 */
    path: '/listening-test',
    name: RouteEnum.ListeningTest,
    component: () => import('@/views/ListeningTestView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 閱讀 */
    path: '/reading',
    name: RouteEnum.Reading,
    component: () => import('@/views/ReadingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 文章內頁 */
    path: '/article/:id',
    name: RouteEnum.Article,
    component: () => import('@/views/ArticleView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 會話 */
    path: '/speaking',
    name: RouteEnum.Speaking,
    component: () => import('@/views/SpeakingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** 寫作 */
    path: '/writing',
    name: RouteEnum.Writing,
    component: () => import('@/views/WritingView.vue'),
    meta: {
      middleware: [auth, freeClass]
    }
  },
  {
    /** AI助教 */
    path: '/aiTutor',
    name: RouteEnum.AiTutor,
    component: () => import('@/views/AiTutorView.vue'),
    meta: {
      middleware: [webview, auth]
    }
  },
  {
    /** 家庭作業 */
    path: '/homework',
    name: RouteEnum.Homework,
    component: () => import('@/views/HomeworkView.vue'),
    meta: {
      middleware: [webview, auth]
    }
  },
  {
    /** 大課表 */
    path: '/schedule',
    name: RouteEnum.Schedule,
    component: () => import('@/views/ScheduleView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 回顧 */
    path: '/videoLibrary',
    name: RouteEnum.VideoLibrary,
    component: () => import('@/views/VideoLibraryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 課程頁 */
    path: '/lesson',
    name: RouteEnum.Lesson,
    component: () => import('@/views/LessonView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 收錄 */
    path: '/collection',
    name: RouteEnum.Collection,
    component: () => import('@/views/CollectionView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeic */
    path: '/toeic',
    name: RouteEnum.Toeic,
    component: () => import('@/views/toeic/ToeicEntryPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicCertificate */
    path: '/toeicCertificate',
    name: RouteEnum.ToeicCertificate,
    component: () => import('@/views/toeic/ToeicCertificate.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicExam */
    path: '/toeicExam',
    name: RouteEnum.ToeicExam,
    component: () => import('@/views/toeic/ToeicExamPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** toeicRecord */
    path: '/toeicRecord',
    name: RouteEnum.ToeicRecord,
    component: () => import('@/views/toeic/ToeicRecordPage.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 複習頁面 */
    path: '/review',
    name: RouteEnum.Review,
    component: () => import('@/views/ReviewView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 閱讀紀錄 */
    path: '/readingRecord',
    name: RouteEnum.ReadingRecord,
    component: () => import('@/views/ReadingRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 課程紀錄 */
    path: '/courseRecord',
    name: RouteEnum.CourseRecord,
    component: () => import('@/views/CourseRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 每週測驗 */
    path: '/weeklyTest',
    name: RouteEnum.WeeklyTest,
    component: () => import('@/views/WeeklyTest.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** CEFR測驗 */
    path: '/cefrTest',
    name: RouteEnum.CefrTest,
    component: () => import('@/views/CefrTest.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 每週測驗紀錄 */
    path: '/weeklyTestRecord',
    name: RouteEnum.WeeklyTestRecord,
    component: () => import('@/views/WeeklyTestRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 學習統計 */
    path: '/learningStatistics',
    name: RouteEnum.LearningStatistics,
    component: () => import('@/views/LearningStatistics.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 一對一課程，用intranet內部系統開的課程 */
    path: '/consulting',
    name: RouteEnum.Consulting,
    component: () => import('@/views/ConsultingView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 日記 */
    path: '/diaryView/:id',
    name: RouteEnum.DiaryView,
    component: () => import('@/views/DiaryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 分享日記連結用 */
    path: '/diaryOpenLink/:openId',
    name: RouteEnum.DiaryOpenLink,
    component: () => import('@/views/DiaryView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 建立、編輯日記 */
    path: '/diaryEdit/:id?',
    name: RouteEnum.DiaryEdit,
    component: () => import('@/views/DiaryEdit.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 點數紀錄 */
    path: '/usageRecord',
    name: RouteEnum.UsageRecord,
    component: () => import('@/views/UsageRecord.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 影片播放 */
    path: '/course-video',
    name: RouteEnum.CourseVideo,
    component: () => import('@/views/CourseVideoView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 客製化首頁 */
    path: '/custom-home-page',
    name: RouteEnum.CustomHomePage,
    component: () => import('@/views/CustomHomeView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 進階搜尋 */
    path: '/advanced-search',
    name: RouteEnum.AdvancedSearch,
    component: () => import('@/views/AdvancedSearchView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** Today */
    path: '/today',
    name: RouteEnum.Today,
    component: () => import('@/views/TodayView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** FunTV */
    path: '/funTv',
    name: 'funTv',
    component: () => import('@/views/FunTvView.vue'),
    meta: {
      middleware: [webview, auth],
      layout: 'NoLayout'
    }
  },
  {
    /** Thank You Page */
    path: '/thank-you',
    name: RouteEnum.ThankYou,
    component: () => import('@/views/ThankYouView.vue'),
    meta: {
      layout: 'NoLayout'
    }
  },
  {
    /** Sitemap */
    path: '/sitemap',
    name: RouteEnum.Sitemap,
    component: () => import('@/views/Sitemap.vue'),
    meta: {
      layout: 'NoLayout'
    }
  },
  {
    /** article copy content */
    path: '/articleCopy',
    name: RouteEnum.ArticleCopy,
    component: () => import('@/views/ArticleCopyView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    /** 訂閱付款頁 */
    path: '/newSubscription/orderPlan',
    name: RouteEnum.OrderPlan,
    component: () => import('@/views/subscription/OrderPlanView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [webview, auth]
    }
  },
  {
    /** 訂閱感謝頁 */
    path: '/newSubscription/paymentResult',
    name: RouteEnum.PaymentResult,
    component: () => import('@/views/subscription/paymentResult.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    path: '/donation/:orderNumber',
    name: RouteEnum.Donation,
    component: () => import('@/views/DonationView.vue'),
    meta: {
      layout: 'NoLayout'
    }
  },
  {
    /** 訂閱頁 */
    path: '/newSubscription',
    name: RouteEnum.Subscription,
    component: () => import('@/views/subscription/index.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [webview]
    }
  },
  {
    /** FAQ */
    path: '/faq',
    name: RouteEnum.FAQ,
    component: () => import('@/views/FAQ.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [webview]
    }
  },
  {
    /** Fun Coin */
    path: '/funCoin',
    name: RouteEnum.FunCoin,
    component: () => import('@/views/FunCoin.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [webview, auth]
    }
  },
  {
    /** 特殊方案訂閱付款頁 */
    path: '/newSubscription/special/orderPlan',
    name: RouteEnum.SpecialOrderPlan,
    component: () => import('@/views/subscription/specialPlan.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 分潤頁 */
    path: '/MLM/:groupId',
    name: RouteEnum.MLM,
    component: () => import('@/views/MLM.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 抽獎頁 */
    path: '/lottery',
    name: RouteEnum.Lottery,
    component: () => import('@/views/LotteryView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 抽獎付款頁 */
    path: '/lotteryApply',
    name: RouteEnum.LotteryApply,
    component: () => import('@/components/lottery/LotteryApply.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [auth]
    }
  },
  {
    /** 大單付款頁 */
    path: '/bigOrder',
    name: RouteEnum.BigOrder,
    component: () => import('@/views/BigOrderView.vue'),
    meta: {
      layout: 'NoLayout',
      middleware: [webview, auth]
    }
  },
  {
    /** 大單紀錄頁 */
    path: '/orderRecord',
    name: RouteEnum.OrderRecord,
    component: () => import('@/views/OrderRecordView.vue'),
    meta: {
      middleware: [webview, auth]
    }
  },
  {
    /** 訪問路徑不存在時一律回到首頁 */
    path: '/:catchAll(.*)',
    redirect: '/'
  }
];

const scrollBehavior = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  savedPosition: any
) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

let previousRouteName: string | null = null;
export const createAppRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: setupLayouts(routes),
    scrollBehavior
  });

  router.beforeEach((to, from, next) => {
    // 檢查是否存在中間件

    if (!to.meta.middleware) {
      return next(); // 如果沒有中間件，直接允許路由繼續
    }
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { to, from, next };
    let idx = 0; // 初始化中間件索引

    // 定義執行中間件的函數
    const run = () => {
      if (idx < middleware.length) {
        const mw = middleware[idx]; // 獲取當前中間件
        mw({
          ...context,
          next: () => {
            idx++; // 移動到下一個中間件
            run(); // 繼續執行下一個中間件
          }
        });
      } else {
        next(); // 所有中間件執行完畢，繼續路由導航
      }
    };
    run(); // 開始執行中間件
  });

  router.beforeEach((to, from) => {
    if (to.path !== '/login' && to.path !== '/loginJunior') {
      contractExpired();
    }
  });

  router.beforeEach((to, from) => {
    //手機版sidebar課表開新視窗會跳出滿版sidebar，暫時擋一下
    const appStore = useAppStore();
    const user = useUserStore();

    const loginPage = user.userData.roleType === RoleType['isJunior'] ? '/loginJunior' : '/login';

    if (appStore.isMobile) {
      //從註冊來會是先thankU頁
      appStore.isFull = user.isAfterSignUp ? from.path === '/thank-you' : from.path === loginPage;
    }
  });

  router.beforeEach((to, from) => {
    previousRouteName = typeof from.name === 'string' ? from.name : null;
  });

  router.afterEach((to, from, next) => {
    //學習建議在註冊後，跳轉到訂閱頁，滚動條消失
    if (to.name === RouteEnum.Subscription) {
      document.querySelector('body')?.setAttribute('style', 'overflow: auto !important;');
    }
  });

  return { router, previousRouteName };
};

const contractExpired = () => {
  const user = useUserStore();
  const { currentYear, currentMonth, currentDate } = useCurrentDay();
  console.log(`${currentYear.value}-${currentMonth.value}-${currentDate.value}`);
  const IsExpired = computed(() => {
    return (
      compareDates(
        `${currentYear.value}-${currentMonth.value}-${currentDate.value}`,
        user.userData.endDate,
        'after'
      ) &&
      compareDates(
        `${currentYear.value}-${currentMonth.value}-${currentDate.value}`,
        user.userData.pointDate as string,
        'after'
      )
    );
  });

  if (IsExpired.value) {
    usePopStore().popList.warning = true;
  } else {
    console.log('試用沒過期');
  }
};
